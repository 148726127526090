<template>
    <div class="reader-edition-info flex md-minus:flex-col lg-plus:flex-row justify-between pl-5 pr-1 py-2 bg-grey-200">
        <div id="reader__titleinfo__default__top" class="flex flex-row justify-between items-center">

            <div id="reader__titleinfo__default" class="flex flex-row  items-center text-base">
                <div v-show="this.default_author" id="reader__titleinfo__default--author flex flex-row items-center" v-html="this.default_author"></div>
                <span v-show="this.default_title && this.default_title.trim() !== ''" id="reader__titleinfo__default--title" class="mr1">,</span>
                <div v-show="this.default_title && this.default_title.trim() !== ''" v-html="this.default_title"></div>
                <span v-show="this.default_locus && this.default_locus.trim() !== ''" class="mr1">, </span>
                <div v-show="this.default_locus" id="reader__titleinfo__default--locus" v-html="this.default_locus"></div>
                <div v-if="this.default_author.trim().length > 0 || this.default_title.trim().length > 0 || this.default_locus.trim().length > 0" class="mr-4"></div>
            </div>

        </div>
        <div>


            <!-- FALL 1: Text ist einer Lerneinheit zugeordnet -->
            <div v-if="this.$parent.data.lerneinheit" id="reader-edition-info--lerneinheit" class="flex flex-row justify-start items-center text-base text-grey-700">
                <div class="mr-2 font-bold uppercase"> {{ this.$parent.data.lerneinheit.name }}</div>
                <div v-if="this.$parent.data.lerneinheit.buch && this.$parent.data.lerneinheit.buch.length > 0" class=" flex flex-row justify-start items-center  ">
                    <div class="mr-3">erschienen in:</div>
                    <i class="fas fa-book"></i>

                    <div class="ml-2 flex flex-row justify-start items-center" v-for="buch in this.$parent.data.lerneinheit.buch">
                        <div>{{ buch.titel }}</div>
                        <div v-if="buch.untertitel">, {{ buch.untertitel }}</div>
                        <div v-if="buch.autor_hg">, hg. von {{ buch.autor_hg }}</div>
                        <div v-if="buch.verlag">, {{ buch.verlag }}</div>
                        <div v-if="buch.erschjahr" class="ml-1">{{ buch.erschjahr }}</div>
                    </div>
                </div>

            </div>
            <!-- FALL 2: Text ist einem Buch zugeordnet -->
            <div v-else-if="this.$parent.data.buch" id="reader-edition-info--buch" class="">


                <button id="reader-edition-info--buch-button" class=" flex flex-row justify-start items-center text-sm ">
                    <transition name="fade">

                        <!-- Ggf. später mittels eines Attributs namens showBuchInfo ausblendbar machen--->
                        <div class="mr-2 flex md-minus:flex-col lg-plus:flex-row md-minus:text-xs lg-plus:text-sm md-minus:items-start lg-plus:items-center">
                            <div class=" md-minus:hidden lg-plus:flex mr-2">erschienen in:</div>
                            <div class=" flex flex-row items-center">

                            <hermeneus-icon name="hi-buch" height="18" width="18" class="hi-color-grey-400 hi-color-secondary-hover md-minus:hidden lg-plus:flex"></hermeneus-icon>
                                <div class="flex flex-row ml-2">

                            {{ this.$parent.data.buch.titel }}, {{ this.$parent.data.buch.untertitel }}, hg. von {{ this.$parent.data.buch.autor_hg }}, {{ this.$parent.data.buch.verlag }}
                            {{ this.$parent.data.buch.erschjahr }}
                                </div>
                            </div>
                        </div>
                    </transition>
                </button>
            </div>
            <!-- FALL 3: Text ist nicht zugeordnet -->

            <div v-else id="reader-edition-info--pub-info" class="flex flex-row justify-start items-center">


                <div id="reader-edition-info--description" v-show="$parent.data.textdata.user.role === 'editor' || $parent.data.textdata.user.role === 'admin'"
                     class="flex flex-row justify-start items-center text-grey-500 text-base w-full">
                    <div id="reader-edition-info--edited-by">herausgegeben und bearbeitet von</div>
                    <img :src="'/storage/users/'+ user_displayed.trim() +'/avatar.png'" class="ml-1 md-minus:hidden" alt="">
                    <div id="reader-edition-info--author" v-html="user_displayed" class="ml-1 text-secondary_color"></div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
import QuerySelectors from "../reader.class_queryselectors.js";

export default {
    name: "reader-edition-info",
    props: ['header'],
    data: function () {
        return {
            default_author: this.header.querySelector(QuerySelectors.titlestmt_author).innerHTML,
            default_title: this.header.querySelector(QuerySelectors.titlestmt_title).innerHTML,
            default_locus: this.header.querySelector(QuerySelectors.seriesstmt_biblscope).innerHTML
        }
    },
    computed: {
        user_displayed: function () {

            if (this.$parent.data.buch) {
                return this.$parent.data.buch.verlag
            } else {

                return this.$parent.data.textdata?.user?.username ?? '';
            }
        }
    },
  mounted() {
  }
}
</script>

<style scoped>

</style>